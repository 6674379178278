@import '~styles/base.module.scss';

.programView {
  position: relative;
  min-height: 150px;
}

.line {
  position: absolute;
  top: 30px;
  left: 0;
  width: 316px;
  max-width: 316px;
  margin-top: $space-l;
}

.rectangle {
  position: absolute;
  width: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $color-white;
  text-align: center;
}
