.modal_title {
  text-align: center;
  margin: auto;
  margin-bottom: 30px;

  span {
    font-size: 20px;
    font-weight: 600;
  }
}

.centered {
}

.modal {
  width: 437px;
  height: auto;
  padding: 30px;
  background: white;
  z-index: 210;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 210;

  @media screen and (max-width: 700px) {
    width: 70vw;
    height: auto !important;
  }

  @media screen and (max-width: 500px) {
    margin-top: 35px;
    width: 90% !important;
    height: auto !important;
  }

  .form_block {
    height: 40%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8e8e8e;
    font-size: medium;
  }
}

.dateInput {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ed1c24;
  margin-left: 15px;

  &:focus {
    outline: none;
  }
}

.tempInput {
  padding: 10px 2px;
  border-radius: 50px;
  border: 1px solid #ed1c24;
  max-width: 110px;
  text-align: center;
}

.icon_wrapper {
  cursor: pointer;
  width: 10px;
  margin: 4px;

  &img {
    display: block;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}

.alignSubmit {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.submitButton {
  background-color: #ed1c24;
  border-radius: 50px;
  height: 40px;
  color: white;
  border: none;
  padding: 10px;

  img {
    margin-right: 7px;
  }
}

.form_block_text {
  margin-right: 10px;
}
