.darkBG {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px); // This be the blur
  width: 100%;
  height: 100%;
  z-index: 200;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 210;

  @media screen and (max-width: 700px) {
    width: 90%;
  }
}

.modal {
  width: 437px;
  height: 270px;
  padding: 30px;
  background: white;
  z-index: 210;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media screen and (max-width: 700px) {
    width: 100%;
  }

  .form_block {
    height: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8e8e8e;
    font-size: medium;
  }
}

.dateInput {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ed1c24;
  margin-left: 15px;

  &:focus {
    outline: none;
  }
}

.tempInput {
  padding: 10px 2px;
  border-radius: 50px;
  border: 1px solid #ed1c24;
  max-width: 110px;
  text-align: center;
}

.icon_wrapper {
  cursor: pointer;
  width: 10px;
  margin: 8px;

  &img {
    display: block;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}

.alignSubmit {
  margin-top: 40px;
  float: right;
  margin-left: auto;
  margin-right: 20px;
}

.submitButton {
  background-color: #ed1c24;
  border-radius: 50px;
  height: 40px;
  color: white;
  border: none;
  padding: 10px;

  img {
    margin-right: 7px;
  }
}
